
import { defineComponent, ref, onMounted } from 'vue'
import { NButton, NSelect, NInput, useMessage, NAlert, NSpin } from 'naive-ui'
import { useSites } from '@/store'
import { SearchOutline } from '@vicons/ionicons5'
import { API } from '@/api/api'

export default defineComponent({
  components: {
    SearchOutline,
    NSelect,
    NAlert,
    NInput,
    NButton,
    NSpin,
  },
  setup() {
    const message = useMessage()
    const sites = useSites()
    const isLoading = ref<boolean>(false)
    const formRef = ref<{
      user: string | null
      pass: string | null
      host: string | null
      id_site: number | null
      id: string | null
    }>({
      user: 'transpar_consult',
      host: 'transparencia-am.com.br',
      pass: null,
      id_site: null,
      id: null,
    })

    const sitesOptions = ref([])

    async function migrateData() {
      try {
        if (formRef.value.id_site == null) {
          message.error('Selecione o site de destino')
          return
        }
        if (formRef.value.id == null) {
          message.error('Digite um ID Entidade válido')
          return
        }
        isLoading.value = true
        const data = formRef.value
        const response = await API().post(
          `/dashboard/sites/migration/origin`,
          data
        )

        if (response.status == 200) {
          message.success(
            'Os documentos foram inseridos com sucesso no banco de dados de destino'
          )
        } else {
          message.error('Ocorreu um erro ao migrar, consulte o log')
          console.error(response.data)
        }
      } catch (err) {
        console.error(err)
        message.error(
          'Erro ao conectar ao banco de dados, verifique as configurações de login e se o banco dados esta com permissão de acesso externo'
        )
        return
      } finally {
        isLoading.value = false
      }
    }

    onMounted(async () => {
      await sites.fetch()
      sitesOptions.value = sites.data.map((site: any) => ({
        label: `${site.id} - ${site.nome}`,
        value: site.id,
      }))
    })

    return {
      sites,
      sitesOptions,
      migrateData,
      formRef,
      isLoading,
    }
  },
})
